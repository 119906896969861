/**
 * Sort period facet values based on timespan.
 *
 * @param facetValues
 *  An array of facet values.
 * @returns {*}
 *   Sorted facet values.
 */
export function sortPeriodFacetValues(facetValues) {
  return facetValues.sort((a, b) => {
    // Special case the not specified value to sort last.
    if (a.value === 'https://vangoghworldwide.org/data/concept/empty_value') {
      return 1;
    }
    if (b.value === 'https://vangoghworldwide.org/data/concept/empty_value') {
      return -1;
    }

    const atimespan = a.tuple[0].attributes.timespan[0];
    const btimespan = b.tuple[0].attributes.timespan[0];

    return spaceShip(atimespan.begin_of_the_begin, btimespan.begin_of_the_begin);
  });
}

/**
 * Implements the <=> operator..
 *
 * @param a
 *   First value to compare.
 * @param b
 *   Second value to compare.
 * @returns {number}
 *  -1, 0, 1 if a is less than, equal, greater than b.
 */
function spaceShip(a, b) {
  if (a === b) {
    return 0;
  }
  return a < b ? -1 : 1;
}
