import React, { useState } from 'react';
import SortingView from './Sorting';
import ResultsPagerNav from './ResultsPagerNav';
import { ReactComponent as CloseIcon } from '../assets/images/icon-close.svg';
import CollapsibleFacet from './CollapsibleFacet';
import MultiCheckboxLabelFacet from './MultiCheckboxLabelFacet';
import splitAndSwapName from '../utils/Display/splitAndSwapName';
import { sortPeriodFacetValues } from '../utils/Spinque/FacetHelpers';
import styled from 'styled-components';
import mediaQueries from '../style/variables/mediaQueries';
import { colors } from '../style/variables/colors';
import { gutters } from '../style/variables/sizes';
import GridContainer from '../layout/GridContainer';
import { Sorting } from '@elastic/react-search-ui';
import ResultsGrid from './ResultsGrid';
import ResultsTable from './ResultsTable';
import { withSearch } from '@elastic/react-search-ui';
import { ReactComponent as FilterIcon } from '../assets/images/icon-filter.svg';
import SearchSummary from './SearchSummary';
import SearchActiveFilters from './SearchActiveFilters';

const StyledFacetsAndResults = styled(GridContainer)`
  .search-results-container {
    display: flex;
    flex-direction: column;

    > div,
    > nav {
      border-top: 1px solid ${colors.border};
      &:last-child {
        border-bottom: 1px solid ${colors.border};
      }
    }

    .search-summary-wrapper {
      order: -2;
      min-height: 3.75em;
      padding: 1em 0;
      display: flex;
      align-items: center;
      font-size: 18px;
    }
    .results-sorting {
      order: -1;
    }
  }
  .facets-container {
    ${mediaQueries.lg} {
      order: -1;
    }
  }
`;

const StyledCurrentFilters = styled(GridContainer)`
  min-height: 80px;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;

  .current-filters__count,
  .current-filters__list {
    margin: 8px 0;
  }

  ${mediaQueries.lg} {
    min-height: 100px;
  }

  .button-filter-toggle {
    line-height: 40px;
    padding: 0 1rem;
    > * {
      padding: 0;

      + * {
        padding-left: 1rem;
      }
    }
    ${mediaQueries.lg} {
      display: none;
    }
  }

  .filter-summary {
    ${mediaQueries.max_lg} {
      display: none;
    }
  }

  .filter-count {
    display: inline-block;
    vertical-align: text-bottom;
    text-align: center;
    width: 21px;
    height: 21px;
    line-height: 21px;
    border-radius: 50%;
    color: ${colors.filterCount};
    background-color: ${colors.filterCountBg};
  }
`;

const FacetsContainer = styled.div`
  ${mediaQueries.max_lg} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    &.closed {
      display: none;
    }
    &.open {
      display: block;
      background-color: ${colors.background};
      padding: 0 ${gutters.xs};
    }
  }

  .facets-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    // Set margin-bottom:-1px to hide the top border of the first facet.
    margin: 0 -${gutters.xs} -1px;
    padding: 0 ${gutters.xs};
    background-color: ${colors.background};
    border-bottom: 1px solid ${colors.border};
    position: sticky;
    top: 0;

    h2 {
      font-size: 1rem;
      margin: 0;
    }
  }
`;

const StyledSorting = styled(Sorting)`
  display: flex;
  align-items: center;
  ${mediaQueries.md} {
    height: 100px;
  }

  .sui-sorting__label {
    margin-right: 0.5em;
  }
  .sui-select {
    display: flex;
    margin: 1.5rem 0;

    &__control {
      border: 1px solid ${colors.ink};
      display: flex;
    }
    &__value-container {
      width: 200px;
      line-height: 38px;
      height: 38px;
    }
    &__single-value {
      color: ${colors.ink};
      margin: 0;
    }
    &__indicator {
      svg {
        display: block;
        margin: 0 14px;
      }
    }
    &__menu {
      margin: 0;
      border: 1px solid ${colors.ink};
      border-top: none;
      border-radius: 0;
    }
    &__menu-list {
      padding: 0.5rem;
    }
    &__option {
      cursor: default;
      &:hover {
        background-color: ${colors.backgroundAlternate};
      }
    }
  }
`;

/**
 * Renders a SearchUI component.
 *
 * For all paths that render this component, you MUST:
 *   - Use the <LinkToSearch> component to render links to that path.
 *   - Make sure that useIsOnAnySearchPage() returns `true` for that path.
 *
 * @param current
 * @param totalPages
 * @param totalResults
 * @param pagingStart
 * @param pagingEnd
 * @param resultSearchTerm
 * @param filters
 * @param fixedFilters
 *   An array of the field names of fixed filters. This is used for filters that
 *   cannot be modified by the user, and will be excluded from the current
 *   filters UI.
 * @param fixedFilterLabel
 *   A string describing the fixed filter(s), to be used in the summary.
 * @param displaySummary
 *   Toggles the search summary component (off by default).
 * @returns {JSX.Element}
 * @constructor
 */
const SearchUI = ({
  current,
  totalPages,
  totalResults,
  pagingStart,
  pagingEnd,
  resultSearchTerm,
  filters,
  fixedFilters = [],
  fixedFilterLabel,
  displaySummary = false,
}) => {
  const [filterOverlay, setFilterOverlay] = useState(false);
  const toggleFilterOverlay = () => {
    setFilterOverlay(!filterOverlay);
  };

  // @todo Allow user to switch the results view mode.
  let resultsComponent = <ResultsGrid />;
  const [resultsViewMode, setResultsViewMode] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const toggleResultsViewMode = () => {
    if (resultsViewMode === 'grid') {
      setResultsViewMode('table');
      resultsComponent = <ResultsTable />;
    } else {
      setResultsViewMode('grid');
      resultsComponent = <ResultsGrid />;
    }
  };

  // Artworks and search endpoints have different default sort options.
  const sortOptions = {
    artworks: [
      {
        name: 'F number (asc)',
        value: '',
        direction: '',
      },
      {
        name: 'F number (desc)',
        value: 'sort_fnumber',
        direction: 'desc',
      },
      {
        name: 'Date (asc)',
        value: 'sort_date',
        direction: 'asc',
      },
      {
        name: 'Date (desc)',
        value: 'sort_date',
        direction: 'desc',
      },
    ],
    search: [
      {
        name: 'Relevance',
        value: '',
        direction: '',
      },
      {
        name: 'F number (asc)',
        value: 'sort_fnumber',
        direction: 'asc',
      },
      {
        name: 'F number (desc)',
        value: 'sort_fnumber',
        direction: 'desc',
      },
      {
        name: 'Date (asc)',
        value: 'sort_date',
        direction: 'asc',
      },
      {
        name: 'Date (desc)',
        value: 'sort_date',
        direction: 'desc',
      },
    ],
  };

  // For the interface showing the current set of filters, exclude the ones
  // provided in the fixed filters array. This is used for instance on an
  // exhibition page, where the search results are filtered by exhibition and
  // the exhibition filter cannot be unset.
  const mutableFilters = filters.filter(
    facet => !fixedFilters.includes(facet.field)
  );

  return (
    <>
      <StyledCurrentFilters className="current-filters">
        {totalResults > 0 && (
          <>
            <div className="grid-col--lg-3 current-filters__count">
              <label className="button--black button-filter-toggle">
                <FilterIcon aria-hidden="true" />
                <button onClick={toggleFilterOverlay}>Filter</button>
                {mutableFilters.length ? (
                  <span className="filter-count-wrapper">
                    <span className="filter-count">
                      {mutableFilters.length}
                    </span>
                  </span>
                ) : null}
              </label>
              {mutableFilters.length ? (
                <div className="filter-summary">
                  <span className="filter-count">{mutableFilters.length}</span>{' '}
                  {mutableFilters.length === 1 ? 'filter' : 'filters'}
                </div>
              ) : null}
            </div>
            <div className="grid-col--lg-9 current-filters__list">
              <SearchActiveFilters filters={mutableFilters} />
            </div>
          </>
        )}
      </StyledCurrentFilters>
      <StyledFacetsAndResults>
        <div
          className="search-results-container grid-col--lg-9"
          id="search-results"
        >
          <h2 className="visually-hidden">Search results</h2>
          {displaySummary && (
            <div className="search-summary-wrapper">
              <SearchSummary fixedFilterLabel={fixedFilterLabel} />
            </div>
          )}
          {totalResults > 0 && resultsComponent}
          {totalResults > 0 && (
            <div className="results-sorting">
              <h3 className="visually-hidden">Sort options</h3>
              <StyledSorting
                view={SortingView}
                label="Sort:"
                sortOptions={
                  resultSearchTerm ? sortOptions.search : sortOptions.artworks
                }
              />
            </div>
          )}
          {totalResults > 0 && (
            <ResultsPagerNav
              current={current}
              totalPages={totalPages}
              totalResults={totalResults}
              pagingStart={pagingStart}
              pagingEnd={pagingEnd}
            />
          )}
        </div>
        <FacetsContainer
          className={`facets-container grid-col--lg-3 ${
            filterOverlay ? 'open' : 'closed'
          }`}
        >
          <div
            className={`facets-header ${
              filterOverlay ? '' : 'visually-hidden'
            }`}
          >
            <label className="button--white">
              <CloseIcon aria-hidden="true" />
              <button onClick={toggleFilterOverlay} className="visually-hidden">
                Close filters
              </button>
            </label>
            <h2>Filters</h2>
            {/* @todo Implement clear filters button here. */}
            <span className="clear-filters button-white">&nbsp;</span>
          </div>
          <CollapsibleFacet
            field="creator"
            label="Artist"
            view={MultiCheckboxLabelFacet}
            show={9}
            collapsible={true}
            collapsed={false}
            rewriteLabel={splitAndSwapName}
          />
          <CollapsibleFacet
            field="period"
            label="Period"
            view={MultiCheckboxLabelFacet}
            show={11}
            collapsible={true}
            collapsed={false}
            sortFacetValues={sortPeriodFacetValues}
          />
          <CollapsibleFacet
            field="owner_country"
            label="Collection location"
            view={MultiCheckboxLabelFacet}
            collapsible={true}
            collapsed={true}
          />
          <CollapsibleFacet
            field="owner"
            label="Collection"
            view={MultiCheckboxLabelFacet}
            collapsible={true}
            collapsed={false}
            isFilterable={true}
          />
          <CollapsibleFacet
            field="type"
            label="Object category"
            view={MultiCheckboxLabelFacet}
            collapsible={true}
            collapsed={false}
          />
          <CollapsibleFacet
            field="material"
            label="Material"
            view={MultiCheckboxLabelFacet}
            collapsible={true}
            collapsed={true}
          />
          <CollapsibleFacet
            field="support"
            label="Support"
            view={MultiCheckboxLabelFacet}
            collapsible={true}
            collapsed={true}
          />
          <CollapsibleFacet
            field="technique"
            label="Technique"
            view={MultiCheckboxLabelFacet}
            collapsible={true}
            collapsed={true}
          />
          <CollapsibleFacet
            field="subject"
            label="Subject"
            view={MultiCheckboxLabelFacet}
            collapsible={true}
            collapsed={true}
          />
          <CollapsibleFacet
            field="research_type"
            label="Research type"
            view={MultiCheckboxLabelFacet}
            collapsible={true}
            collapsed={true}
          />
        </FacetsContainer>
      </StyledFacetsAndResults>
    </>
  );
};

export default withSearch(
  ({
    current,
    totalPages,
    totalResults,
    pagingStart,
    pagingEnd,
    resultSearchTerm,
    filters,
  }) => ({
    current,
    totalPages,
    totalResults,
    pagingStart,
    pagingEnd,
    resultSearchTerm,
    filters,
  })
)(SearchUI);
